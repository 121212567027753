import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class TicketManagerService {
  tickets = [
    {
      orderID: 'abcdef123456',
      denomination: 30,
      version: 12345,
      ticketName: 'ticket 01',
      businessName: 'device 01',
      paymentID: 'pay01',
      purchaseDateAndTime: '2023-08-31 01:01:01',
      numberOfPeople: '4/1',
      expirationDate: '2024-08-31',
      refundDeadline: '2023-12-31',
      activationDateAndTime: '2024-09-01',
      refundTime: '30ms',
      refundDateAndTime: '',
      situation: ''
    },
    {
      orderID: 'abcdef123457',
      denomination: 45,
      version: 12346,
      ticketName: 'ticket 02',
      businessName: 'device 02',
      paymentID: 'pay02',
      purchaseDateAndTime: '2023-08-31 01:01:01',
      numberOfPeople: '4/4',
      expirationDate: '2024-08-31',
      refundDeadline: '2023-12-31',
      activationDateAndTime: '2024-09-01',
      refundTime: '30ms',
      refundDateAndTime: '2023-08-31 01:01:02',
      situation: ''
    },
    {
      orderID: 'abcdef123458',
      denomination: 45,
      version: 12347,
      ticketName: 'ticket 03',
      businessName: 'device 03',
      paymentID: 'pay03',
      purchaseDateAndTime: '2023-08-31 01:02:01',
      numberOfPeople: '4/4',
      expirationDate: '2024-08-31',
      refundDeadline: '2023-12-31',
      activationDateAndTime: '2024-09-01',
      refundTime: '30ms',
      refundDateAndTime: '',
      situation: ''
    },
    {
      orderID: 'abcdef123459',
      denomination: 30,
      version: 12349,
      ticketName: 'ticket 02',
      businessName: 'device 04',
      paymentID: 'pay04',
      purchaseDateAndTime: '2023-08-31 01:02:01',
      numberOfPeople: '4/4',
      expirationDate: '2023-09-10',
      refundDeadline: '2023-12-31',
      activationDateAndTime: '2024-09-01',
      refundTime: '30ms',
      refundDateAndTime: '2023-08-31 01:01:02',
      situation: ''
    }
  ];

  informationAccount: any;
  constructor(private http: HttpClient, private dialogService: DialogService, private translateService: TranslateService) {}

  /**
   * Get list tickets Sold
   */
  getListTicketsSold(): Array<any> {
    return this.tickets;
  }

  public setInformationAccount(informationAccount: any) {
    this.informationAccount = informationAccount;
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public getOrders(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    let isReserveParam;
    if (payload.isReserve == true) {
      isReserveParam = true;
    } else if (payload.isReserve == false) {
      isReserveParam = false;
    } else {
      isReserveParam = '';
    }
    const params = {
      orderId: payload.orderId,
      ticketType: payload.ticketType,
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate,
      userId: payload.userId,
      isReserve: isReserveParam
    };
    const options = { headers: headers, params: params };
    return this.http.get<any>(`${this.informationAccount.api_url}orders`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  public getOrder(orderId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = { orderId: orderId };
    const options = { headers: headers, params: params };
    return this.http.get<any>(`${this.informationAccount.api_url}order`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  public getPlanTicket(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = {
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate
    };
    return this.http.get(`${this.informationAccount.api_url}reports/ticket`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
      headers: headers
    });
  }

  public getItemList(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = {
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate,
      status: payload.status
    };
    return this.http.get(`${this.informationAccount.api_url}reports/order`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
      headers: headers
    });
  }

  public getCouponTicket(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = {
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate
    };
    return this.http.get(`${this.informationAccount.api_url}reports/coupon`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
      headers: headers
    });
  }

  public reportsReservation(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = {
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate
    };
    return this.http.get(`${this.informationAccount.api_url}reports/reservation`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
      headers: headers
    });
  }

  /**
   * refundWeb
   * @param payload
   * @returns
   */
  public refundWeb(payload: any) {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .post<any>(`${this.informationAccount.api_url}order/refund/web`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getReservationPrices - API A-1
   * @param payload
   * @returns
   */
  getReservationPrices(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .get<any>(`${this.informationAccount.api_url}reservation-prices`, { params: payload, headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getReservations - API B.1
   * @param payload
   * @returns
   */
  getReservations(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .get<any>(`${this.informationAccount.api_url}reservations`, { params: payload, headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getReservation - API C.1
   * @param payload
   * @returns
   */
  getReservation(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .get<any>(`${this.informationAccount.api_url}reservation`, { params: payload, headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * postReservation - API C-2
   * @param payload
   * @returns
   */
  postReservation(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .post<any>(`${this.informationAccount.api_url}reservation`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * putReservation - API C-3
   * @param payload
   * @returns
   */
  putReservation(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .put<any>(`${this.informationAccount.api_url}reservation`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * deleteReservation - API C-4
   * @param payload
   * @returns
   */
  deleteReservation(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .put<any>(`${this.informationAccount.api_url}reservation/status/cancel`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getStocks - API Z-1
   * @param payload
   * @returns
   */
  getStocks(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .get<any>(`${this.informationAccount.api_url}stocks`, { params: payload, headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getMaximumRecord
   */
  getMaximumRecord(): Observable<number> {
    const ticketManagerUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/ticket-manager';
    return this.http.get<number>(`${ticketManagerUrl}/get-maximum-record`).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getStocksCalendar - API F-1
   * @param payload
   * @returns
   */
  getStocksCalendar(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .get<any>(`${this.informationAccount.api_url}stocks/calendar`, { params: payload, headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }
}
